<div class="d-flex align-items-center mb-2" [class.file-too-big]="tooLarge">
  <ui-icon
    [icon]="tooLarge ? 'circle-warning' : 'attachment'"
    [sizeInPixels]="20"
    [color]="tooLarge ? 'red' : 'charcoal'"
  />
  <div class="file-name ms-2 flex-fill">{{ file.name }}</div>
  <div class="file-size ms-2">{{ file.size | uiFileSize }}</div>
  <button ui-icon-button (click)="click()">
    <ui-icon icon="bin" [sizeInPixels]="20" color="granite" />
  </button>
</div>
@if (tooLarge) {
  <div class="validation d-flex align-items-center">File size is too large. Max file size is 3 MB</div>
}
