import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UIDialogConfirmData } from './dialog-confirm-data.interface';

@Component({
  selector: 'ui-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIDialogConfirmComponent {
  get title(): string {
    return this.data.title;
  }

  get description(): string {
    return this.data.description;
  }

  get confirmButtonText(): string {
    return this.data.confirmButtonText ?? 'Confirm';
  }

  get cancelButtonText(): string {
    return this.data.cancelButtonText ?? 'Back';
  }

  constructor(
    private readonly dialogRef: MatDialogRef<UIDialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UIDialogConfirmData,
  ) {}

  confirmButton(): void {
    this.dialogRef.close(true);
  }

  cancelButton(): void {
    this.dialogRef.close(false);
  }
}
