<div
  class="list-item"
  [class.list-item--with-prefix]="prefix.children.length"
  [class.list-item--with-status]="status.children.length"
>
  <div class="list-item__prefix" #prefix>
    <ng-content select="[ui-prefix]" />
  </div>
  <div class="list-item__content">
    <div class="list-item-title"><ng-content select="[ui-list-item-title]" /></div>
    <div class="list-item-label"><ng-content select="[ui-list-item-label]" /></div>
    @if (subtitle.children.length || value.children.length) {
      <div class="list-item-gap"></div>
    }
    <div class="list-item-subtitle" #subtitle><ng-content select="[ui-list-item-subtitle]" /></div>
    <div class="list-item-value" #value><ng-content select="[ui-list-item-value]" /></div>
    <div class="list-item-status" #status><ng-content select="[ui-list-item-status]" /></div>
  </div>
  <div class="list-item__suffix">
    <div class="suffix-margin">
      <ng-content select="[ui-suffix]" />
    </div>
  </div>
  <div class="list-item__chevron-right">
    <ui-icon icon="chevron-right" color="granite" />
  </div>
  <div class="list-item__chevron-down">
    <ui-icon icon="chevron-down" color="granite" />
  </div>
</div>
