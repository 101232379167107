@if (snackbar.icon) {
  <ui-icon class="me-2" [icon]="snackbar.icon" [color]="snackbar.color ?? 'primary'" />
}

<span matSnackBarLabel data-cy="snackbar-message"> {{ data.message }} </span>

@if (snackbar.actionText) {
  <span matSnackBarActions class="ms-auto text-break">
    <button
      ui-button
      type="text"
      [color]="snackbar.color"
      matSnackBarAction
      class="link-button"
      (click)="snackBarRef.dismissWithAction()"
    >
      {{ snackbar.actionText }}
    </button>
  </span>
}
