@if (steps) {
  <div class="stepper mt-4 mb-4 d-flex me-4 ms-4">
    @for (step of steps; track step; let stepIndex = $index) {
      <div
        class="step d-flex justify-content-center align-items-center"
        [class.step--done]="currentStep() > stepIndex"
        [class.step--active]="currentStep() === stepIndex"
      >
        @if (currentStep() <= stepIndex) {
          {{ stepIndex + 1 }}
        }
        @if (currentStep() > stepIndex) {
          <ui-icon icon="check-single" color="pure-white" />
        }
      </div>
      @if (stepIndex !== steps.length - 1) {
        <div class="step-line flex-fill"></div>
      }
    }
  </div>
}
@if (steps) {
  @for (item of steps; track item; let index = $index) {
    <div>
      @if (index === currentStep()) {
        <ng-container [ngTemplateOutlet]="item.content" />
      }
    </div>
  }
}
