<form class="row" [formGroup]="phoneNumberForm" (ngSubmit)="onSubmit()">
  <ng-content select="label" />
  <ui-form-field class="col-4">
    <select
      [formControl]="countryCode"
      [class.error]="phoneNumberForm.touched && phoneNumberForm.hasError(INVALID_PHONE_ERROR)"
    >
      <option [value]="null" selected disabled hidden>Select</option>
      @for (countryCode of countryCodes(); track countryCode.code) {
        <option [value]="countryCode.code">
          {{ countryCode.code }}
        </option>
      }
    </select>
  </ui-form-field>
  <ui-form-field class="col-8">
    <input
      [formControl]="nationalNumber"
      type="tel"
      autofocus
      [class.error]="phoneNumberForm.touched && phoneNumberForm.hasError(INVALID_PHONE_ERROR)"
      [attr.placeholder]="'countries.' + countryISOCode + '.placeholder' | translate"
    />
  </ui-form-field>

  @if (
    (countryCode.touched && countryCode.hasError('required')) ||
    (nationalNumber.touched && nationalNumber.hasError('required'))
  ) {
    <ui-form-field-error> Phone number cannot be empty </ui-form-field-error>
  }
  @if (phoneNumberForm.touched && phoneNumberForm.hasError(INVALID_PHONE_ERROR)) {
    <ui-form-field-error> Phone number is not valid </ui-form-field-error>
  }
</form>
