/* eslint-disable @typescript-eslint/naming-convention */

import { BrandingColorMap } from '../../branding-color-map.interface';

export const BRANDING_COLOR_MAP_ABNIC: BrandingColorMap = {
  'primary-100': '#ebf2fc',
  'primary-200': '#aac7f1',
  'primary-400': '#0b5ed7',
  'primary-600': '#084296',
  'primary-800': '#042452',
};
