@if ((showOverlay$ | async) === true) {
  <div class="cookies-overlay">
    <p>
      {{ 'analytics.cookies.overlay.description' | translate }}
      <button ui-inline-button (click)="openTermsAndConditions()">
        {{ 'generic.termsAndConditions' | translate }}
      </button>
      &amp;
      <button ui-inline-button (click)="openPrivacyStatement()">
        {{ 'generic.privacyStatement' | translate }}</button
      >.
    </p>
    <h4 class="mt-4 mb-45">
      <button ui-inline-button (click)="manageCookies()">
        {{ 'analytics.cookies.overlay.manageCookies' | translate }}
      </button>
    </h4>
    <p class="mb-0 pt-2">
      <button ui-button (click)="close()">{{ 'generic.close' | translate }}</button>
    </p>
  </div>
}
