<div class="form-field">
  <div class="form-field-header">
    <div class="form-field-header__label">
      <ng-content select="label" />
    </div>
    <div class="form-field-header__action">
      <ng-content select="button[ui-inline-button]" />
    </div>
  </div>
  <div class="form-field-input">
    <div
      class="form-field-input__suffix"
      [class.form-field-input__suffix--hidden]="!suffix.children.length || input.children.item(0)?.tagName === 'SELECT'"
      #suffix
    >
      <ng-content select="[ui-suffix]" />
    </div>
    <div class="form-field-input__prefix" [class.form-field-input__prefix--hidden]="!prefix.children.length" #prefix>
      <ng-content select="[ui-prefix]" />
    </div>
    <div
      class="form-field-input__input"
      [class.form-field-input--has-suffix]="!!suffix.children.length"
      [class.form-field-input--has-prefix]="!!prefix.children.length"
      #input
    >
      <ng-content select="input, select, mat-radio-group, textarea" #input />

      @if (input.children.item(0)?.tagName === 'SELECT') {
        <div class="form-field-input__suffix form-field-input__suffix--no-events">
          <button ui-icon-button [disabled]="input.children.item(0)?.hasAttribute('disabled')" tabindex="-1">
            <ui-icon icon="chevron-down" color="granite" />
          </button>
        </div>
      }
    </div>
  </div>
  <div class="form-field__errors">
    <ng-content select="ui-form-field-error" />
  </div>
</div>
