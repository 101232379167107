import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BrandingService } from '@feature/branding';
import { NavController } from '@ionic/angular';
import { Environment, EnvironmentService } from '@util/environment';

@Component({
  selector: 'ui-app-bar',
  templateUrl: './app-bar.component.html',
  styleUrls: ['./app-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIAppBarComponent {
  private trigger = 0;

  constructor(
    private readonly env: EnvironmentService,
    private readonly navCtrl: NavController,
    private readonly brandingService: BrandingService,
  ) {}

  alertEnv(): void {
    this.trigger++;
    if (this.trigger % 3 == 0) {
      const version: string = this.env.info.version;
      const { country, stage }: Environment = this.env.environment;
      const branding: Environment['branding'] = this.brandingService.brand();
      alert(`Environment: ${country}-${stage}\nVersion: ${version}\nBranding: ${branding}\nOrigin: ${window.origin}`);
    }
  }
}
