import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError } from '@data-access/api/error';
import { UserOperationDataService, UserOperationMember } from '@data-access/api/user-operation';
import { HeapService } from '@feature/analytics';
import { BehaviorSubject, EMPTY, Observable, of, retry, timer } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { AUTH_LOCAL_STORAGE_USER_KEY } from '../../constants/local-storage-user-key.constant';
import { CpUserResponse } from '../../interfaces/user-response.interface';
import { JWT, parseJwt } from '../../util/jwt.util';
import { AuthUsermanagementDataService } from '../usermanagement/usermanagement-data.service';

const RETRY_TIMEOUT: number = 1000;
const RETRY_COUNT: number = 30;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  private readonly _user$: BehaviorSubject<CpUserResponse | null> = new BehaviorSubject<CpUserResponse | null>(
    this.getUserResponseFromLocalStorage(),
  );
  // TODO: consider this, it is only used in MemberService for the username.
  readonly user$: Observable<CpUserResponse | null> = this._user$
    .asObservable()
    .pipe(filter((user: CpUserResponse | null) => !!user));

  readonly isLoggedIn$: Observable<boolean> = this._user$.pipe(
    tap((user: CpUserResponse | null) => user && this.heapService.identify(user.externalKey)),
    map((user: CpUserResponse | null) => !!user),
  );

  constructor(
    private readonly usermanagementService: AuthUsermanagementDataService,
    private readonly userOperationDataService: UserOperationDataService,
    private readonly heapService: HeapService,
  ) {}

  login$(username: string, password: string): Observable<CpUserResponse> {
    return this.usermanagementService.login$(username, password).pipe(
      map((userResponse: CpUserResponse) => this.validateNoMissingRoles(userResponse)),
      retry({
        count: RETRY_COUNT,
        delay: (error: HttpErrorResponse) => {
          if (this.userHasNotYetBeenGrantedAccess(error)) {
            return timer(RETRY_TIMEOUT);
          }
          throw error;
        },
      }),
      tap((userResponse: CpUserResponse) => this.updateUserResponseInLocalStorage(userResponse)),
      switchMap((userResponse: CpUserResponse) =>
        this.checkIfMemberExists$(userResponse.userDetails.username).pipe(
          catchError(() => {
            this.removeUserResponseFromLocalStorage();
            throw new ApiError('error.member-not-found');
          }),
          map(() => {
            this._user$.next(userResponse);
            return userResponse;
          }),
        ),
      ),
    );
  }

  logout(): void {
    this.removeUserResponseFromLocalStorage();
    this._user$.next(null);
    this.heapService.resetIdentify();
  }

  getToken$(): Observable<string | null> {
    const user: CpUserResponse | null = this.getUserResponseFromLocalStorage();
    if (!user) {
      return of(null);
    }
    const nowInSeconds: number = new Date().getTime() / 1000;
    if (nowInSeconds < user.expirationDate) {
      return of(user.accessToken);
    }
    return this.usermanagementService.refresh$().pipe(
      tap((userResponse: CpUserResponse) => {
        this.updateUserResponseInLocalStorage(userResponse);
        this._user$.next(userResponse);
      }),
      map((userResponse: CpUserResponse) => userResponse.accessToken),
      catchError(() => {
        console.debug('Could not refresh session, logging out.');
        this.logout();
        return EMPTY;
      }),
    );
  }

  private checkIfMemberExists$(username: string): Observable<boolean> {
    return this.userOperationDataService.getMember$(username).pipe(
      retry({
        count: RETRY_COUNT,
        delay: (error: HttpErrorResponse) => {
          if (this.userHasNotYetBeenGrantedAccess(error)) {
            return timer(RETRY_TIMEOUT);
          }
          throw error;
        },
      }),
      map((member: boolean | UserOperationMember) => !!member),
    );
  }

  private getUserResponseFromLocalStorage(): CpUserResponse | null {
    try {
      return JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_USER_KEY) as string);
    } catch (e: unknown) {
      return null;
    }
  }

  private updateUserResponseInLocalStorage(user: CpUserResponse): void {
    localStorage.setItem(AUTH_LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
  }

  private removeUserResponseFromLocalStorage(): void {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_USER_KEY);
  }

  private validateNoMissingRoles(userResponse: CpUserResponse): CpUserResponse {
    const { roles }: JWT = parseJwt(userResponse.accessToken);
    if (!roles || roles.length < 1) {
      throw new Error('no roles found');
    }
    if (!roles.includes('PARTICIPANT_MEMBER')) {
      throw new Error('user is not a member');
    }
    return userResponse;
  }

  /*
   * The user is granted access to the member by askari-connect which is an async process. The
   * error response will be a 403 until the user has been granted access.
   */
  private userHasNotYetBeenGrantedAccess(error: HttpErrorResponse | ApiError): boolean {
    if (error instanceof HttpErrorResponse) {
      return error.status === 403;
    }
    return error.originalError?.status === 403;
  }
}
